<template>
  <div class="contact-data fill-height">

    <v-row class="fill-height">
      <left-side-image/>
      <v-col cols="12" md="7">

        <nav-bar-page/>

        <v-container>

          <v-row justify="center" class="mt-0">
            <v-col cols="12" class="mt-0 py-0">
              <div class="my-0 pt-0 primary--text">
                <h1 class="text-center today-regular text-title" >¡PREPÁRATE PARA LA SELFIE!</h1>
              </div>
            </v-col>
            <v-col cols="11" md="6" class="mt-0 py-0">
              <div class="my-0 pt-0 primary--text">
                <h3 class="primary--text text-center mb-0 today-light line-height-1">Realiza una validación biométrica de tu cara para mayor seguridad en el proceso</h3>
              </div>
            </v-col>
            <v-col cols="12" md="12" lg="12">


              <div class="mt-4 text-center" :class="{ 'flash' : isShotPhoto }">


<!--                <video  ref="camera" :width="370" :height="278" style="border-radius:20px; transform: scale(-1, 1)" controls autoplay></video>-->

                <web-cam
                    v-if="!isPhotoTaken"
                    ref="webcam"
                    :device-id="deviceId"
                    width="370"
                    height="278"
                    @started="onStarted"
                    @stopped="onStopped"
                    @error="onError"
                    @cameras="onCameras"
                    @camera-change="onCameraChange"
                    style="border-radius:20px; transform: scale(-1, 1)"
                />


                <div v-if="isPhotoTaken" class="text-center col-md-12">
                  <figure class="figure">
                    <img :src="selfieImage"
                         width="370"
                         height="278" class="img-responsive"
                         style="transform: scale(-1, 1)"/>
                  </figure>
                </div>

                <div v-if="!isPhotoTaken">
                  <div class="text-center mt-4">
                    <v-btn
                        @click="onCapture"
                        color="primary"
                        class="px-12 text-capitalize vardi-button"
                        elevation="0"
                        large>
                      Tomar foto
                    </v-btn>
                  </div>
                </div>

                <v-row v-if="isPhotoTaken" class="mt-4">
                  <v-col cols="12" sm="1" md="4" lg="4">
                  </v-col>

                  <v-col cols="12" sm="5" md="4" lg="4">
                    <v-btn
                        @click="goPolicies"
                        :loading="loading"
                        :disabled="loading"
                        color="primary"
                        block
                        class=" text-capitalize vardi-button"
                        elevation="0"
                        large>
                      Continuar
                    </v-btn>
                  </v-col>

                  <v-col cols="12" sm="5" md="4" lg="4">
                  </v-col>

<!--                  <v-col cols="12" sm="5" md="4" lg="4">
                    <v-btn
                        @click="onCapture"
                        color="white"
                        block
                        class="text-capitalize vardi-button primary&#45;&#45;text"
                        large>
                      Volver a capturar
                    </v-btn>
                  </v-col>-->

                  <v-col cols="12" sm="1" md="2" lg="2">
                  </v-col>
                </v-row>

                <v-img v-if="frontPreviewImage" :src="frontPreviewImage" height="160" class="mt-2" contain></v-img>

              </div>

            </v-col>
          </v-row>


        </v-container>
      </v-col>
    </v-row>

    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        bottom
        right>
      <div v-html="snackbarMessage"></div>
    </v-snackbar>

  </div>
</template>

<script>
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import NavBarPage from "@/components/Layouts/NavBarPage";
import {locals} from "@/helpers/locals";
import {api} from "@/helpers/axios";
import {constants} from "@/helpers/constants";
// or
import { WebCam } from "vue-web-cam";

export default {
  name: 'TakeSelfie',
  components: {LeftSideImage, NavBarPage, WebCam},
  data() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: '#',
      user: null,
      loading: false,
      selfieImage: null,
      snackbar: false,
      snackbarMessage: '',
      timeout: 3000,
      frontPreviewImage: null,
      selectedFileFront: null,

      img: null,
      camera: null,
      deviceId: null,
      devices: []
    }
  },
  mounted() {
    if(this.isCameraOpen) {
      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.stopCameraStream();
    } else {
      this.isCameraOpen = true;
      this.createCameraElement();
    }
  },

  watch: {
    camera: function(id) {
      this.deviceId = id;
    },
    devices: function() {
      // Once we have a list select the first one
      const [first, ...tail] = this.devices;
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    }
  },

  methods: {
    onCapture() {
      if(this.$refs.webcam) {
        this.selfieImage = this.$refs.webcam.capture();
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      setTimeout(() => {
        if(this.isPhotoTaken) {
          this.onStop();
        } else {
          this.onStart();
        }
      }, 100);


    },
    onStarted(stream) {
      console.log("On Started Event", stream);
    },
    onStopped(stream) {
      console.log("On Stopped Event", stream);
    },
    onStop() {
      this.$refs.webcam.stop();
    },
    onStart() {
      this.$refs.webcam.start();
    },
    onError(error) {
      console.log("On Error Event", error);
    },
    onCameras(cameras) {
      this.devices = cameras;
      console.log("On Cameras Event", cameras);
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
    },

    goPolicies: function () {
      this.loading = true;
      this.user = locals.getLocal('user');

      // Convertir base64 a png
      var arr = this.selfieImage.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      var selfieFile = new File([u8arr], 'selfie.png', {
        type: mime
      });
      // Fin de convertir el base64

      if (this.user.login) {

        var formData = new FormData();
        formData.append('document_id', this.user.document_id);
        formData.append('document_type', this.user.document_type);
        formData.append('face_model', selfieFile);

        api.postFile(constants.endPoints.login, formData, true).then(function (response) {

          this.loading = false;

          if (response.data.errors != null) {
            let errors = response.data.errors;
            let message = '';
            for (var [key, value] of Object.entries(errors)) {
              message = message + value + '<br>';
            }
            this.snackbarMessage = message;
            this.snackbar = true;

            this.onCapture();
          } else {

            this.user.access_token = response.data.data.token;
            this.user.token_type = response.data.data.token_type;
            this.user.login = true;
            locals.setLocal('user', JSON.stringify(this.user));

            this.isCameraOpen = false;
            this.isPhotoTaken = false;
            this.isShotPhoto = false;

            this.$router.push('/polizas')
          }
        }.bind(this)).catch(function (error) {
          let errors = error.response.data.errors;
          let message = '';
          try {
            for (var [key, value] of Object.entries(errors)) {
              message = message + value + '<br>';
            }
          } catch (e) {
            console.log(e);
            message = e;
          }

          this.snackbarMessage = message;
          this.snackbar = true;
          this.loading = false;

          this.onCapture();
        }.bind(this));

      } else {

        var formData = new FormData();
        formData.append('document_id', this.user.document_id);
        formData.append('document_type', this.user.document_type);
        formData.append('coupon', this.user.coupon);
        formData.append('validate_document_id', this.user.validate_document_id);
        formData.append('face_model', selfieFile);

        api.postFile(constants.endPoints.registerUser, formData, true).then(function (response) {

          this.loading = false;

          if (response.data.errors != null) {
            let errors = response.data.errors;
            let message = '';
            for (var [key, value] of Object.entries(errors)) {
              message = message + value + '<br>';
            }
            this.snackbarMessage = message;
            this.snackbar = true;
            this.user.login = false;
            locals.setLocal('user', JSON.stringify(this.user));

            this.onCapture();
          } else {

            this.user.access_token = response.data.data.token;
            this.user.token_type = response.data.data.token_type;
            this.user.login = true;
            locals.setLocal('user', JSON.stringify(this.user));

            this.isCameraOpen = false;
            this.isPhotoTaken = false;
            this.isShotPhoto = false;

            this.$router.push('/polizas')
          }

        }.bind(this)).catch(function (error) {
          let errors = error.response.data.errors;
          let message = '';
          for (var [key, value] of Object.entries(errors)) {
            message = message + value + '<br>';
          }
          this.snackbarMessage = message;
          this.snackbar = true;
          this.loading = false;

          this.onCapture();
        }.bind(this))

      }

    },

    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true
      });

      /*navigator.mediaDevices
          .getUserMedia(constraints)
          .then(stream => {
            this.isLoading = false;
            this.$refs.camera.srcObject = stream;
            this.$refs.camera.play();
          })
          .catch(error => {
            this.isLoading = false;
            alert("May the browser didn't support or there is some errors. " + error);
          });*/


    },
    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();
      tracks.forEach(track => {
        track.stop();
      });
    },
    takePhoto() {
      if(!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      setTimeout(() => {
        if(this.isPhotoTaken) {
          this.stopCameraStream();
        } else {
          this.createCameraElement();
        }
      }, 100);


      const context = this.$refs.canvas.getContext('2d');
      context.drawImage(this.$refs.camera, 0, 0, 380, 300);

      this.selfieImage = document.getElementById("photoTaken").toDataURL();

    },

    downloadImage() {
      const download = document.getElementById("downloadPhoto");
      const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg", 1.0)
          .replace("image/jpeg", "image/octet-stream");
      download.setAttribute("href", canvas);
    }
  },
  beforeDestroy() {
    this.stopCameraStream();

  }
}
</script>

<style scoped lang="scss">


</style>